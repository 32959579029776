import React, { useState } from "react";
import { AccordionItem } from "@molecules";
import { LayoutGroup } from "framer-motion";

const Accordion = ({ items }) => {
  const [open, setOpen] = useState(null);
  return (
    <div className="border-y-2 border-white" onMouseLeave={() => setOpen(null)}>
      <LayoutGroup>
        {items?.map((item, i) => {
          return (
            <AccordionItem
              {...item}
              key={item?.uid || i}
              state={[open, setOpen]}
              i={i}
            />
          );
        })}
      </LayoutGroup>
    </div>
  );
};

Accordion.defaultProps = {};

export default Accordion;
